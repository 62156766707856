// + de paramètres possible sur https://swiperjs.com/demos et sur https://swiperjs.com/swiper-api
var swiper1 = new Swiper(".diaporama-swiper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".diaporama-buttonNext",
        prevEl: ".diaporama-buttonPrev",
    },
    pagination: {
        el: ".diaporama-pagination",
        bulletClass: 'swiper-pagination-bullet diaporama-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active diaporama-bullet--active',
        clickable: true,
    },
});

var swiper2 = new Swiper(".widgetCatalogue-swiper", {
    spaceBetween: 30,
    slidesPerView: 1,
    navigation: {
        nextEl: ".widgetCatalogue-buttonNext",
        prevEl: ".widgetCatalogue-buttonPrev",
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});

var swiper3 = new Swiper(".widgetActualite-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet widgetActualite-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active widgetActualite-bullet--active'
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 115,
        }
    }
});

var swiper4 = new Swiper(".ficheProduit-swiper", {
    spaceBetween: 15,
    slidesPerView: 3,
    navigation: {
        nextEl: ".ficheProduit-buttonNext",
        prevEl: ".ficheProduit-buttonPrev",
    },
    breakpoints: {
        370: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 2,
        },
    }
});

var swiper5 = new Swiper('#galerie-slider', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.widgetGalerie-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.widgetGalerie-button-next',
        prevEl: '.widgetGalerie-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});

var swiper6 = new Swiper('#recrutement-slider', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});

var swiper7 = new Swiper('.widgetAuto-swiper', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});

var swiper8 = new Swiper('.widgetAutoVIP-swiper', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

var swiper9 = new Swiper(".ficheProduit-crossSwiper", {
    spaceBetween: 30,
    slidesPerView: 1,
    pagination: {
         el: ".ficheProduit-crossPagination",
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 4,
          spaceBetween: 50,
        }
    },
});
